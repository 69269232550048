<template>
    <div class="search-index">
        <div class="search-handle">
            <form action="/">
                <van-search
                    class="search-input"
                    v-model="params.keyword"
                    placeholder="输入药物类型或药物名称搜索"
                    show-action
                    @search="onSearch"
                    @cancel="onCancel"
                />
            </form>
        </div>
        <div class="search-content">
            <!-- 搜索初始内容 -->
            <template v-if="isOrginal">
                <div class="search-initial">
                    <!-- 推荐内容 -->
                    <div class=" recommend-content">
                        <div class="content-tips recommend-title">
                            <img src="../../assets/icon/icon_hot.png" alt="">
                            <div>推荐的内容</div>
                        </div>
                        <div class="recommend-item">
                            <template v-for="(item, i) in recommendList">
                                <span :key="i" @click.stop.prevent="searchClick(item.keyword)">{{item.keyword}}</span>
                            </template>
                        </div>
                    </div>
                    <!-- 按病种查询 -->
                    <div class="cancer-check">
                        <div class="content-tips check-title">
                            <img src="../../assets/icon/icon_big.png" alt="">
                            <div>按癌种查找</div>
                        </div>
                        <div class="cancer-item">
                            <template v-for="(item, index) in cancerList">
                                <div :key="index" @click.stop.prevent="searchClick(item.illName)">{{item.illName}}</div>
                            </template>
                        </div>
                    </div>
                    
                </div>
            </template>
            <!-- 搜索结果 -->
            <template v-else>
                <div v-if="isShowProject" class="search-result">
                    <div class="search-result-count">为您找到{{total}}个相关项目</div>
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                        >
                        <template style="min-height: 100px" v-for="(item, i) in projectList">
                            <projectItem :projectInfo="item" :key="i"/>
                        </template>
                    </van-list>
                </div>
                <div v-else class="search-noData">
                    <div class="search-noData-tips">暂无匹配项目</div>
                    <div class="search-noData-other">
                        <div class="search-noData-tips1">您可尝试以下方法</div>
                        <div class="search-noData-tips2" @click="uploadCaseHistory ">OCR识别病历，精准匹配</div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { Search, List } from 'vant';
import projectItem from '@/components/project/project-item';
import searchApi from '@api/search/searchApi.js'
import patientApi from '@api/patient/patientApi.js'
import page from '@utils/page';
export default {
    components: {
        [Search.name]: Search,
        [List.name]: List,
        projectItem
    },
    data() {
        return{
            params: {
                pageNo: 1,
                keyword: ''
            },
            recommendList: [],
            cancerList: [],
            isShowProject: true,
            projectList: [],
            loading: false,
            finished: false,
            isOrginal: true,
            total: 0
        }
    },
    created() {
        // 获取病种、推荐列表
        Promise.all([searchApi.getSearchKeyword(),patientApi.diseasList()]).then(res => {
            // console.log(res)
            Object.assign(this, {
                recommendList: res[0].data,
                cancerList: res[1].data
            })
        })
        
    },
    mounted() {
        this.page = new page.pages(this.projectList);
    },
    methods: {
        uploadCaseHistory() {
            patientApi.agreeUpload().then(res => {
                this.$router.push({
                    path: '/uploadMedicalRecord',
                    query: {recordId: res.data}
                })
            })
        },
        onLoad() {
            // 异步更新数据
            this.params.pageNo = this.page.pageNo + ""
            console.log("onload pageNo:---"+ this.params.pageNo);
            this.page.isOver().then(() => {
                 this.finished = true;
                return;
            })
            if(this.params.pageNo>1) this.getSearchList()   //onLoad方法从第二页开始才调用数据查询，避免重复调用，使页码出错

        },
        searchClick(keyword) {
            Object.assign(this.params, {
                keyword,
                pageNo: 1
            })
            this.page.recovery()
            this.isOrginal = false;
            this.finished = false;
            this.getSearchList()
        },
        // 搜索
        onSearch(val) {
            this.isOrginal = false;
            this.finished = false;
            Object.assign(this.params, {
                keyword: val,
                pageNo: 1
            })
            this.page.recovery();
            console.log("click pageNo:---"+ this.params.pageNo);
            this.getSearchList()
        },
        // 取消
        onCancel() {
            this.isOrginal = true;
        },
        // 获取搜索结果
        getSearchList() {
            searchApi.getSearchList(this.params).then(res => {
                this.total = res.data.recordCount
                console.log("total:-----"+this.total);
                this.loading = false;
                this.page.req(res).then(list => {
                    console.log("list size:---"+list.length);
                    console.log("pageno:---"+this.page.pageNo)
                    this.projectList = list
                    console.log("projectList size:---"+this.projectList.length);
                    this.loading = false;

                    this.isShowProject =  this.projectList.length > 0 || false
                    console.log("isShowProject:"+ this.isShowProject);

                })

            })
        }
    }
}
</script>

<style lang="less" scoped>
.search-index {
    min-height: 100vh;
    background: #F7F7F7;
    .search-handle {
        padding: 15px 15px 17px;
        border-bottom: 1px solid #D5D5D5;
        background: #fff;
        .search-input {
            padding: 0;
            .van-search__content {
                // padding: 9px 16px ;
                font-size:14px;
                border-radius: 32px;
                background: #EEEEEE;
            }
            .van-search__action{
                padding: 0 0 0 19px;
                font-size:16px;
                color: #828284;
            }
        }
    }
    .search-content {
        
        .search-initial {
            padding: 20px 15px;
            background: #fff;
            .content-tips {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                img {
                    width: 14px;
                    height: 14px;
                    font-size:0; 
                }
                >div {
                    font-size:14px;
                    color:#828284;
                    margin-left: 7px;
                    line-height: 14px;
                }
            }
            .recommend-item {
                 display: flex;
                flex-wrap: wrap;
                 margin-bottom: 15px;
                span {
                    padding: 5px 15px;
                    margin:0 12px 10px 0;
                    font-size:14px;
                    border-radius:2px;
                    background:rgba(244,244,244,1);
                    text-align: center;
                }
            }
            .cancer-check {
                .cancer-item {
                     display: flex;
                    flex-wrap: wrap;
                   div {
                       width: 107px;
                       height: 33px;
                       padding: 0 10px;
                        margin:0 11px 10px 0;
                       background:rgba(244,244,244,1);
                        border-radius:3px;
                        font-size:14px;
                        line-height: 33px;
                        text-align: center;
                        &:nth-of-type(3n) {
                            margin-right: 0;
                        }
                   }  
                }
            }
        }
        .search-result {
            padding: 20px 15px 30px;
            background: #fff;
            .search-result-count {
                font-size:14px;
                color:rgba(130,130,132,1);
            }
            
        }
        .search-noData {
            .search-noData-tips {
                font-size:16px;
                padding: 20px 15px;
                background: #fff;
                color: #313134;
            }
            .search-noData-other {
                padding: 20px 15px 0;
                .search-noData-tips1 {
                    font-size:14px;
                    color:rgba(130,130,132,1);
                    padding-bottom: 20px;
                }
                .search-noData-tips2 {
                    font-size:16px;
                    color:#0050A2;
                    padding-bottom: 20px;
                    font-weight:500;
                }
            }
        }
    }

}
</style>
