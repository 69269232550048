function pages(list) {
    this.list = list;
    this.pageNo = 1;
    this.pageSize = 10;
    this.isEnd = false;
}
pages.prototype = {
    constructor: 'pages',
    nextPage: function() {
        this.pageNo += 1;
    },
    isEnd: function() {
        this.isEnd = true;
    },
    setPageSize: function(pageSize) {
        this.pageSize = pageSize;
    },
    req: function(res) {
        return new Promise((resolve, reject) => {
            this.pageSize = res.data.pageSize;
            console.log("pagesize is:----"+this.pageSize);
            if (this.pageSize * this.pageNo >= res.data.recordCount && this.isEnd == false) {
                this.pageNo++;
                this.isEnd = true;
                this.list = this.list.concat(res.data.list);
                console.log("res list 1 is:----"+this.list.length);
            } else if (!this.isEnd) {
                this.pageNo++;
                this.list = this.list.concat(res.data.list);
                console.log("res list 2is:----"+this.list.length);
            }

            resolve(this.list);
        })
    },
    isOver: function() {
        return new Promise((resolve, reject) => {
            if (this.isEnd) {
                resolve();
            }
        })
    },
    recovery: function() {
        this.list = [];
        this.pageNo = 1;
        this.isEnd = false;
    }
}
export default {
    pages
}