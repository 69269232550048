<template>
    <div class="project-item" @click="toDetail()">
        <div class='project-item-name'>{{projectInfo.name}}</div>
        <div class='project-item-content '>
        <div class="indications">适应症：{{projectInfo.indication}}</div>
        <div>药物名称：{{projectInfo.medicine}}</div>
        <div>药物类型：{{projectInfo.medicineType}}</div>
        <!-- <div>年龄要求：{{`${projectInfo.ageFrom} - ${projectInfo.ageTo}`}}岁</div> -->
        <div>试验分期：{{projectInfo.stage}}</div>
		<div>CTR编号：{{projectInfo.ctrNo}}</div>
        </div>
        <div class='project-item-status'>
            <!-- 招募状态（0：未开始 1：招募中 2：招募完成） -->
            <div v-if="projectInfo.status === 0" class='item-status-left' style='background:#0050A2;'>未开始</div>
            <div v-if="projectInfo.status === 1" class='item-status-left'>招募中</div>
            <div v-if="projectInfo.status === 2" class='item-status-left' style='background:#ACABAD;'>招募完成</div>
            <!-- <div class='item-status-right'>截止日期 {{projectInfo.finishDate}}</div> -->
        </div> 
    </div>
</template>
<script>
import {toPath} from '@/utils/common';
export default {
    props: {
        projectInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        inquiryIdInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return{}
    },
    methods: {
        toDetail() {
            const { id } = this.projectInfo
            const { inquiryId, inquiryName } = this.inquiryIdInfo
            const queryObj = {id}
            if(inquiryId && inquiryId) Object.assign(queryObj, {inquiryId, inquiryName})
			toPath(this, {
				path:'/clinicalTrialDeail',
				query:{ ...queryObj }
			} )
		}
    },
	mounted() {
		
	}
}
</script>
<style lang="less" scoped>
.project-item {
    width: 100%;
    padding: 15px;
    margin-top:15px;
    background:#fff;
    box-shadow:0px 4px 8px 0px rgba(162,162,162,0.5);
    border-radius:5px;
    .project-item-name {
        width: 100%;
        font-size:16px;
        font-weight:600;
        color:rgba(49,49,52,1);
        // line-height: 1;
        padding-bottom: 15px;
        border-bottom:1px solid #D5D5D5;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .project-item-content {
        width:100%;
        font-size:14px;
        color:#5A5A5D;
        // line-height: 1;
        padding: 15px 0 5px;
        div {
            margin-bottom: 5px;
            line-height: 1.3;
        }
        .indications {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .project-item-status {
        display: flex;
        align-items: center;
        .item-status-left {
            margin-right: 15px;
            padding: 0px 8px;line-height:1.5;
            color: #fff;
            font-size:13px;
            background: #FAC400;
            border-radius:2px;
        }
        .item-status-right {
            font-size:14px;
            color: #ACABAD;
        }
    }
}
</style>
